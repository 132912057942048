import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import {base} from './base';
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
  }
}


class App extends Component {
  constructor(props) {
        super(props)
        // this.setCurrentUser = this.setCurrentUser.bind(this)
        this.state = {
          authenticated: false,
          variables: {},
          // currentUser: null,
          loading: true,
        }
    }

  componentDidMount() {
    this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
      context: this,
      state: 'tenantVariables',
      then(variables){
        this.setState({
            authenticated: false,
            variables: variables,
            loading: false,
        })
      }
    });
  }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto"}}>
        <BrowserRouter onUpdate={logPageView()}>
            <div>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                          exact
                          path="/login"
                          render={(props) => {
                            return <Login variables={this.state.variables} {...props} />
                          }}
                        />
                        <Route
                          exact
                          path="/"
                          render={(props) => {
                            return <MainApp variables={this.state.variables} {...props} />
                          }}
                        />

                        <Redirect to={`/login`}/>
                      </Switch>
                    </div>
                </div>
            </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
